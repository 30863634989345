import React from "react";
import loading from "../images/loader/loading.svg";

const Loader = () => {
  return (
    <div className="loader-screen">
      <img className="loader" src={loading} alt="" />
      <h3>Loading Moverse....</h3>
    </div>
  );
};

export default Loader;

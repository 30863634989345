import React, { useState, useEffect } from "react";
import { IntlProvider } from "react-intl";
import English from "../../translations/en.json";
import Spanish from "../../translations/de.json";
import Turkish from "../../translations/tur.json";
import Chiniese from "../../translations/chn.json";
import Vit from "../../translations/vit.json";
export const Context = React.createContext();
const local = navigator.language;

let lang;
if (local === "en") {
  lang = English;
} else if (local === "tur") {
  lang = Turkish;
} else if (local === "es") {
  lang = Spanish;
} else if (local === "chn") {
  lang = Chiniese;
} else if (local === "vit") {
  lang = Vit;
} else {
  lang = English;
}
function Wrapper(props) {
  const [locale, setLocale] = useState("en");
  const [messages, setMessages] = useState(English);
  const [items, setItems] = useState([]);

  useEffect(() => {
    
    let value = localStorage.getItem("lang");
    //console.log(value);
    //setLocale(value ? value: 'en');
    selectLang(value);
   
  }, []);
  const selectLang = (e)  =>{
    let newLocale = "en";
    if (e)
    {
      newLocale = e
    }
    //const items = JSON.parse(localStorage.getItem('items'));
    
    console.log(e, 'myval');
    localStorage.setItem("lang", newLocale);
    setLocale(newLocale);
    
    if (newLocale === "en"  ) {
      setMessages(English);
      console.log(messages);
      // window.location.reload();
    } else if (newLocale === "tur") {
      setMessages(Turkish);
      console.log(messages);
    }
    else if (newLocale === "es") {
      setMessages(Spanish);
      console.log(messages);
      
    }
    else if (newLocale === "chn") {
      setMessages(Chiniese);
      console.log(messages);
   
    } 
    
    else if (newLocale === "vit") {
      setMessages(Vit);
      console.log(messages);
      
    } 
    else {
      setMessages(English);
      console.log(messages);
      // window.location.reload();
    }
  };
  return (
    <Context.Provider value={{ locale, selectLang }}>
      <IntlProvider locale={locale} messages={messages}>
        {props.children}
      </IntlProvider>
    </Context.Provider>
  );
}

export default Wrapper;

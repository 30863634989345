import React, { Suspense, useEffect, useState } from "react";
import { Routes, BrowserRouter } from "react-router-dom";
import {FormattedMessage, FormattedHTMLMessage} from 'react-intl';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ScrollTop from "./ScrollTop";
import loading from './images/loader/loading.svg';
import Loader from "./pages/Loader";
const Routes1 = React.lazy(() => import("./Routes1"));
function App() {
  const [loading, setLoading] = useState(true);
  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };
  const wait = async (milliseconds = 2000) => {
    await sleep(milliseconds);
    setLoading(false);
  };

  useEffect(() => {
    // wait(2000)
    setLoading(false);
  }, []);
  return [
    <ToastContainer key="toasts" autoClose={3500} hideProgressBar />,
    <BrowserRouter>
      <ScrollTop />
      <Suspense fallback={<Loader/>}>
        
          <Routes1 />
       
      </Suspense>
    </BrowserRouter>,
  ];
}

export default App;
